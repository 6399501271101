import {useState, useEffect, ReactNode} from 'react'
import clsx from 'clsx'
import {useIntl} from 'react-intl'

export type FormField<T> = {
  columns?: number
  id: string
  error?: string
  label: string
  name?: string
  disabled?: boolean
  readonly?: boolean
  embedHtml?: boolean
  autoComplete?: 'on' | 'off'
  className?: string
  placeholder?: string
  inputType:
    | 'text'
    | 'textarea'
    | 'password'
    | 'email'
    | 'tel'
    | 'number'
    | 'select'
    | 'checkbox'
    | 'multiple'
    | 'date'
  units?: string
  href?: string
  options?: Array<{value: any; label: string}>
  getter?: (state: T) => any
  setter?: (state: T, value: any) => T
  maxLength?: number
  required?: boolean
}

export type ChildrenType = {
  afterForm?: ReactNode
  beforeForm?: ReactNode
}

interface Props<T> {
  title?: string
  fields: Array<FormField<T>>
  ctaLabel: string
  onSubmit: (state: T) => void
  toggleEdit?: (event: any) => void
  loading?: boolean
  submitting: boolean
  submittingLabel: string
  initialState: T
  editing: boolean
  disabled?: boolean
  columns?: number
  children?: ChildrenType
  error?: any
  table?: boolean
  editable?: boolean
  state?: T
  setState?: (state: T) => void
}

export function GenericForm<T extends any>({
  title = '',
  fields = [],
  submitting = false,
  disabled = false,
  loading = false,
  ctaLabel = '',
  submittingLabel = '',
  initialState = {} as T,
  editing = false,
  columns = 1,
  children = {} as ChildrenType,
  error = {} as any,
  table = false,
  editable = true,
  onSubmit,
  toggleEdit,
  state,
  setState,
}: Props<T>) {
  const [innerState, setInnerState] = useState<T>(initialState as T)
  const [formState, setFormState] = setState?
    [state, setState] : [innerState, setInnerState]
  const intl = useIntl()

  useEffect(() => {
    setFormState(initialState)
  }, [editing])

  function handleFormSubmit(event: any) {
    event?.preventDefault()
    onSubmit(formState)
  }

  function readableBoolean(value: boolean) {
    if (value) {
      return intl.formatMessage({id: 'genericForm.yes'})
    }
    return intl.formatMessage({id: 'genericForm.no'})
  }

  function getFieldGetter(field: FormField<T>) {
    if (typeof field?.getter === 'function') {
      return field?.getter
    }
    // @ts-ignore
    return (state: T) => state[field?.id]
  }

  function getFieldSetter(field: FormField<T>) {
    if (typeof field?.setter === 'function') {
      return field?.setter
    }
    // @ts-ignore
    return (state: T, value: any) => {
      // @ts-ignore
      const newState = {...state}

      if (field.inputType === 'number') {
        newState[field?.id] = Number(value)
      } else {
        newState[field?.id] = value
      }
      return newState
    }
  }

  return (
    <>
      <div className='d-flex flex-row align-items-center justify-content-between flex-columns'>
        {!!title && <h1 className='d-inline-block mb-10'>{title}</h1>}
        {editable && !loading && toggleEdit && (
          <button type='button' className='mb-10 btn btn-link' onClick={toggleEdit}>
            {editing ? (
              <>
                <span className='fas fa-times fa-flip-horizontal mr-5' />
                <span className=''>
                  &nbsp;&nbsp;{intl.formatMessage({id: 'genericForm.cancelEditing'})}
                </span>
              </>
            ) : (
              <>
                <span className='fas fa-pen fa-flip-horizontal mr-5' />
                <span className=''>&nbsp;&nbsp;{intl.formatMessage({id: 'genericForm.edit'})}</span>
              </>
            )}
          </button>
        )}
      </div>
      {loading ? (
        <>
          <div className='spinner-border text-primary' role='status' />
        </>
      ) : (
        <>
          {children.beforeForm}
          <form onSubmit={handleFormSubmit}>
            <div className='row'>
              {Object.keys(initialState)?.length > 0 &&
                fields.map((field, i) => (
                  <div
                    key={field.id}
                    className={`col-md-${(12 / columns) * (field.columns || 1)} ${
                      table ? 'mb-1' : 'mb-5'
                    }`}
                  >
                    {(!table || (table && i < columns)) && (
                      <label className={clsx('fs-6 fw-bold mb-2', table && 'mb-5')}>
                        {field.label}
                      </label>
                    )}
                    {editing &&
                      !field?.readonly &&
                      ['email', 'text', 'password', 'number', 'tel', 'date'].includes(
                        field.inputType
                      ) && (
                        <input
                          required={field?.required}
                          maxLength={field?.maxLength}
                          disabled={field.disabled || submitting}
                          autoComplete={field.autoComplete}
                          type={field.inputType}
                          className={clsx('form-control form-control-solid', field.className)}
                          placeholder={field.placeholder}
                          name={field.name || field.id}
                          value={getFieldGetter(field)(formState)}
                          onChange={(e) =>
                            setFormState(getFieldSetter(field)(formState, e.target.value))
                          }
                        />
                      )}
                    {editing && !field?.readonly && field.inputType === 'textarea' && (
                      <textarea
                        required={field?.required}
                        disabled={field.disabled || submitting}
                        autoComplete={field.autoComplete}
                        className={clsx('form-control form-control-solid', field.className)}
                        placeholder={field.placeholder}
                        name={field.name || field.id}
                        onChange={(e) =>
                          setFormState(getFieldSetter(field)(formState, e.target.value))
                        }
                      >
                        {getFieldGetter(field)(formState)}
                      </textarea>
                    )}
                    {editing && !field?.readonly && 'select' === field.inputType && (
                      <select
                        required={field?.required}
                        disabled={field.disabled || submitting}
                        autoComplete={field.autoComplete}
                        aria-label={field.label}
                        data-placeholder={field.placeholder}
                        data-dropdown-parent='#kt_modal_update_details'
                        className={clsx('form-select form-select-solid', field.className)}
                        name={field.name || field.id}
                        value={getFieldGetter(field)(formState)}
                        onChange={(e) =>
                          setFormState(getFieldSetter(field)(formState, e.target.value))
                        }
                      >
                        <option key='default' value='' selected disabled hidden>
                          {intl.formatMessage({id: 'genericForm.selectPlaceholder'})}
                        </option>
                        {field?.options?.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    )}
                    {editing && !field?.readonly && 'checkbox' === field.inputType && (
                      <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid mt-3'>
                        <input
                          required={field?.required}
                          disabled={field.disabled || submitting}
                          autoComplete={field.autoComplete}
                          type={field.inputType}
                          className={clsx('form-check-input', field.className)}
                          placeholder={field.placeholder}
                          name={field.name || field.id}
                          checked={getFieldGetter(field)(formState) || false}
                          onChange={(e) =>
                            setFormState(
                              getFieldSetter(field)(formState, !getFieldGetter(field)(formState))
                            )
                          }
                        />
                        <span className='form-check-label fw-bold text-muted'></span>
                      </label>
                    )}
                    {editing && !field?.readonly && 'multiple' === field.inputType && (
                      <div style={{
                        maxHeight: '300px',
                        overflowY: 'scroll',
                      }}>
                        {field?.options?.map((option) => (
                          <label
                            className='form-check-label mt-3 d-block cursor-pointer'
                            key={option.value}
                          >
                            <input
                              required={field?.required}
                              id={option.value}
                              disabled={field.disabled || submitting}
                              autoComplete={field.autoComplete}
                              type='checkbox'
                              className={clsx('form-check-input me-4', field.className)}
                              placeholder={field.placeholder}
                              name={field.name}
                              value={option.value}
                              checked={getFieldGetter(field)(formState)
                                .split(',')
                                .includes(option.value)}
                              onChange={(e) =>
                                setFormState(
                                  getFieldSetter(field)(
                                    formState,
                                    e.target.checked
                                      ? [
                                          ...getFieldGetter(field)(formState)
                                            .split(',')
                                            .filter((token) => !!token.length),
                                          option.value,
                                        ].join(',')
                                      : getFieldGetter(field)(formState)
                                          .split(',')
                                          .filter((token) => !!token.length)
                                          .filter((value) => value !== option.value)
                                          .join(',')
                                  )
                                )
                              }
                            />
                            {option.label}
                          </label>
                        ))}
                      </div>
                    )}
                    {editing && error[field.id]?.length > 0 && (
                      <p className='mt-2 help-block text-danger'>{error[field.id][0]}</p>
                    )}
                    {(!editing || (editing && field?.readonly)) && (
                      <p className={clsx('fs-6', editing && 'pt-4')}>
                        {field.inputType === 'checkbox' &&
                          (readableBoolean(getFieldGetter(field)(initialState)) || '-')}
                        {field.inputType === 'select' &&
                          (field.options?.find(
                            (option) => option.value === getFieldGetter(field)(initialState)
                          )?.label ||
                            '-')}
                        {field.inputType === 'multiple' &&
                          (field.options
                            ?.filter((option) =>
                              getFieldGetter(field)(initialState).split(',').includes(option.value)
                            )
                            ?.map((option) => option.label)
                            .join(', ') ||
                            '-')}
                        {field.inputType === 'date' && !(getFieldGetter(field)(initialState)) && '-'}
                        {field.inputType === 'date' && !!(getFieldGetter(field)(initialState)) &&
                          new Date(getFieldGetter(field)(initialState)).toLocaleString()}
                        {!['checkbox', 'select', 'multiple', 'date'].includes(field.inputType) &&
                          (getFieldGetter(field)(initialState) === undefined ||
                          getFieldGetter(field)(initialState) === '' ||
                          getFieldGetter(field)(initialState) === null ? (
                            '-'
                          ) : (
                            <a href={field.href}>
                              {field.embedHtml ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: getFieldGetter(field)(initialState),
                                  }}
                                />
                              ) : (
                                <>
                                  {getFieldGetter(field)(initialState)} {field.units}
                                </>
                              )}
                            </a>
                          ))}
                      </p>
                    )}
                  </div>
                ))}
            </div>
            {editing && (
              <div className='d-flex mt-4 justify-content-end gap-4 flex-wrap'>
                {children.afterForm}
                <button
                  type='submit'
                  className='btn btn-primary d-inline-block ms-auto text-nowrap'
                  data-kt-users-modal-action='submit'
                  disabled={submitting || disabled}
                  data-kt-indicator={submitting ? 'on' : 'off'}
                >
                  <span className='indicator-label'>{ctaLabel}</span>
                  <span className='indicator-progress'>
                    {submittingLabel}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div>
            )}
          </form>
        </>
      )}
    </>
  )
}
