import clsx from 'clsx'
import {FC, useState, useEffect} from 'react'
import {Dropdown} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'

import {TaskModel} from '../models/TaskModel'
import {
  bulkAddComment,
  bulkSolveTasks,
  listTaskProfiles,
  listTaskResolutions,
} from '../services/TaskCRUD'
import {BulkResolutionModal} from './BulkResolutionModal'
import {BulkCommentModal} from './BulkCommentModal'
import {solvableStates} from './Task'
import Select from 'react-select'
import {TaskProfileModel} from '../models/TaskProfileModel'
import {TaskResolutionModel} from '../models/TaskResolutionModel'
import {useSelector} from 'react-redux'
import {TaskResolutionModal} from './TaskResolutionModal'
import {staffUsersSelector, groupsSelector, pendingAgentTasksSliceSelector} from '../redux/TaskSelectors'
import {TaskSetResolutionModel} from '../models/TaskSetResolutionModel'
import DateRangeSelector from '../../../components/DateRangeSelector'

type TaskTableProps = {
  tasks: Array<TaskModel>
  displayOwner?: boolean
  displayClient?: boolean
  displayPet?: boolean
  onRefresh?: () => void
  onTaskProfileSelected?: (taskProfileId: number) => void
  onTaskResolutionSelected?: (taskResolutionId: number) => void
  onDateRangeSelected?: (since?: string, until?: string) => void
  onOrderSelected?:  (order: string) => void
  onCurrentAttemptNumberSelected?: (currentAttemptNumber: number) => void
  loading?: boolean
  children?: any
  bulkResolveDisabled?: boolean
  bulkCommentDisabled?: boolean
  bulkReassignDisabled?: boolean
  orderById?: boolean
}

type BULK_STATE = 'loading' | 'idle'

const TaskTable: FC<TaskTableProps> = ({
  tasks,
  displayOwner = true,
  displayClient = true,
  displayPet = true,
  onRefresh,
  onTaskProfileSelected,
  onTaskResolutionSelected,
  onDateRangeSelected,
  onOrderSelected,
  onCurrentAttemptNumberSelected,
  loading = false,
  bulkResolveDisabled = false,
  bulkCommentDisabled = true,
  bulkReassignDisabled = false,
  orderById = false,
  children,
}) => {
  const intl = useIntl()
  const staffUsers = useSelector(staffUsersSelector)
  const groups = useSelector(groupsSelector)
  const [bulkResolutionModalVisible, setBulkResolutionModalVisible] = useState(false)
  const [bulkCommentModalVisible, setBulkCommentModalVisible] = useState(false)
  const [bulkReassignModalVisible, setBulkReassignModalVisible] = useState(false)
  const [selectedTasks, setSelectedTasks] = useState<Array<TaskModel>>([])
  const [bulkState, setBulkState] = useState<BULK_STATE>('idle')
  const [taskProfiles, setTaskProfiles] = useState<Array<TaskProfileModel>>([])
  const [taskResolutions, setResolutions] = useState<Array<TaskResolutionModel>>([])
  const {
    currentAttemptNumber: selectedAttemptNumber,
    taskProfile: selectedTaskProfile,
    taskResolution: selectedResolution,
    order: selectedOrder,
  } = useSelector(pendingAgentTasksSliceSelector)

  async function fetchTaskProfiles() {
    try {
      const {data} = await listTaskProfiles()
      setTaskProfiles(data)
    } catch (error) {
      console.warn(error)
    }
  }

  async function fetchTaskResolutions() {
    try {
      const {data} = await listTaskResolutions()
      setResolutions(data)
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(() => {
    fetchTaskProfiles()
    fetchTaskResolutions()
  }, [])

  useEffect(() => {
    setSelectedTasks([])
  }, [tasks])
  
  async function onBulkResolution(resolutionId) {
    setBulkResolutionModalVisible(false)

    const resolutionObject = selectedTasks
      ?.find((task) => task.resolutions.some((resolution) => resolution.id === resolutionId))
      ?.resolutions?.find((resolution) => resolution.id === resolutionId)

    const confirms = window.confirm(
      intl.formatMessage(
        {id: 'tasks.bulkResolveConfirmation'},
        {count: selectedTasks.length, resolution: resolutionObject?.name}
      )
    )
    if (confirms) {
      try {
        setBulkState('loading')
        await bulkSolveTasks(
          selectedTasks?.map((task) => task.id),
          resolutionId
        )
        toast.success(intl.formatMessage({id: 'tasks.bulkResolveSuccess'}))
        onRefresh?.()
      } catch (error) {
        console.error({error})
        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error)
        } else if (error?.response?.data?.owner) {
          toast.error(intl.formatMessage({id: 'tasks.noResolveOwner'}))
        } else {
          toast.error(intl.formatMessage({id: 'tasks.bulkResolveError'}))
        }
      } finally {
        setBulkState('idle')
      }
    }
  }

  async function onBulkReassign(
    taskResolution: TaskSetResolutionModel,
    replaceAgent: boolean,
    _isAlarm: boolean
  ) {
    const {owner, task_resolution} = taskResolution
    setBulkReassignModalVisible(false)
    const staffUser = staffUsers.find((user) => user.id === owner)
    const confirms = window.confirm(
      intl.formatMessage(
        {id: 'tasks.bulkReassignConfirmation'},
        {count: selectedTasks.length, agent: staffUser?.email}
      )
    )
    if (confirms) {
      try {
        setBulkState('loading')
        await bulkSolveTasks(
          selectedTasks?.map((task) => task.id),
          task_resolution,
          owner,
          replaceAgent
        )
        toast.success(intl.formatMessage({id: 'tasks.bulkReassignSuccess'}))
        onRefresh?.()
      } catch (error) {
        console.error({error})
        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error)
        } else if (error?.response?.data?.owner) {
          toast.error(intl.formatMessage({id: 'tasks.noResolveOwner'}))
        } else {
          toast.error(intl.formatMessage({id: 'tasks.bulkReassignError'}))
        }
      } finally {
        setBulkState('idle')
      }
    }
  }

  function onBulkComment(comment) {
    setBulkCommentModalVisible(false)

    const confirms = window.confirm(
      intl.formatMessage({id: 'tasks.bulkCommentConfirmation'}, {count: selectedTasks.length})
    )
    if (confirms) {
      try {
        setBulkState('loading')
        bulkAddComment(
          selectedTasks?.map((task) => task.id),
          comment
        )
        toast.success(intl.formatMessage({id: 'tasks.bulkCommentSuccess'}))
        onRefresh?.()
      } catch (error) {
        console.error({error})
        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error)
        } else {
          toast.error(intl.formatMessage({id: 'tasks.bulkCommentError'}))
        }
      } finally {
        setBulkState('idle')
      }
    }
  }

  function getResolutionOptions() {
    return [
      {value: -1, label: intl.formatMessage({id: 'tasks.unresolved'})},
      ...taskResolutions
        .map((taskResolution) => {
          const parent = taskResolutions.find((res) => res.id === taskResolution.parent)
          return {
            value: taskResolution.id,
            label: parent ? `${parent.name} > ${taskResolution.name}` : taskResolution.name,
          }
        })
        ?.sort((a, b) => a.label.localeCompare(b.label)),
    ]
  }

  function getTaskProfileOptions() {
    return [
      {value: null, label: intl.formatMessage({id: 'tasks.noFilter'})},
      ...taskProfiles
        .map((taskProfile) => ({
          value: taskProfile.id,
          label: taskProfile.name,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)),
    ]
  }

  function filterByDateRange(since, until) {
    let stringSince = `${since?.getFullYear()}-${(since?.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${since?.getDate().toString().padStart(2, '0')}`
    let stringUntil = `${until?.getFullYear()}-${(until?.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${until?.getDate().toString().padStart(2, '0')}`

    onDateRangeSelected?.(stringSince, stringUntil)
  }

  const getControlStyles = (baseStyles, state) => ({
    ...baseStyles,
    height: '44px',
    marginTop: '3px',
  })

  return (
    <>
      <menu className='d-flex px-0 gap-4 align-items-end justify-content-between flex-wrap'>
        <section className='d-flex px-0 gap-4 align-items-end'>
          <div className=''>
            <label className={clsx('mb-2', !selectedTasks?.length && 'text-muted')}>
              {selectedTasks?.length > 0 ? (
                <>
                  {intl.formatMessage(
                    {id: 'tasks.countSelectedTasks'},
                    {count: selectedTasks?.length}
                  )}
                </>
              ) : (
                <>{intl.formatMessage({id: 'tasks.noSelectedTasks'})}</>
              )}
            </label>
            <Dropdown>
              <Dropdown.Toggle variant='primary' disabled={selectedTasks.length < 1}>
                <span className='me-4 fas fa-layer-group' />
                {intl.formatMessage({id: 'tasks.bulkActions'})}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {!bulkResolveDisabled && (
                  <Dropdown.Item
                    className='py-3 ps-5 fs-6'
                    onClick={() => setBulkResolutionModalVisible(true)}
                  >
                    <span className='me-4 fas fa-check text-muted' />
                    {intl.formatMessage({id: 'tasks.resolveInBulk'})}
                  </Dropdown.Item>
                )}
                {!bulkReassignDisabled && (
                  <Dropdown.Item
                    className='py-3 ps-5 fs-6'
                    onClick={() => setBulkReassignModalVisible(true)}
                  >
                    <span className='me-4 fas fa-user text-muted' />
                    {intl.formatMessage({id: 'tasks.reassignInBulk'})}
                  </Dropdown.Item>
                )}
                {!bulkCommentDisabled && (
                  <Dropdown.Item
                    className='py-3 ps-5 fs-6'
                    onClick={() => setBulkCommentModalVisible(true)}
                  >
                    <span className='me-4 fas fa-comment text-muted' />
                    {intl.formatMessage({id: 'tasks.addCommentInBulk'})}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {onRefresh && (
            <button className='btn btn-primary mb-0' onClick={onRefresh} disabled={loading}>
              {loading ? (
                <div className='spinner-border spinner-border-sm me-4' role='status' />
              ) : (
                <span className='fas fa-sync me-4' />
              )}
              {intl.formatMessage({id: 'tasks.refresh'})}
            </button>
          )}
          {children}
        </section>
        <section className='d-flex px-0 gap-4 align-items-end'>
          {onDateRangeSelected && (
            <DateRangeSelector
              initialSince={null}
              initialUntil={null}
              onSelected={filterByDateRange}
              disableFutureDates={false}
            />
          )}
          {onTaskResolutionSelected && (
            <div style={{width: 220}}>
              <label className='mb-1 fw-bolder'>
                {intl.formatMessage({id: 'tasks.filterByTaskResolution'})}
              </label>
              <Select 
                className="react-select-container"
                classNamePrefix="react-select" 
                styles={{  control: getControlStyles as any, }}
                value={getResolutionOptions().find((option) => option.value === selectedResolution)}
                isDisabled={loading}
                options={getResolutionOptions()}
                placeholder={intl.formatMessage({id: 'tasks.selectTaskResolution'})}
                onChange={(option) => onTaskResolutionSelected(option?.value)}
              />
            </div>
          )}
          {onTaskProfileSelected && (
            <div style={{width: 230}}>
              <label className='mb-1 fw-bolder'>
                {intl.formatMessage({id: 'tasks.filterByTaskProfile'})}
              </label>
              <Select 
                className="react-select-container"
                classNamePrefix="react-select" 
                styles={{  control: getControlStyles as any, }}
                isDisabled={loading}
                options={getTaskProfileOptions()}
                value={getTaskProfileOptions().find(
                  (option) => option.value === selectedTaskProfile
                )}
                placeholder={intl.formatMessage({id: 'tasks.selectTaskProfile'})}
                onChange={(option) => onTaskProfileSelected(option?.value)}
              />
            </div>
          )}
          {
            onOrderSelected && (
              <div style={{width: 180}}>
                <label className='mb-1 fw-bolder'>
                  {intl.formatMessage({id: 'tasks.orderBy'})}
                </label>
                <Select 
                  className="react-select-container"
                  classNamePrefix="react-select" 
                  styles={{  control: getControlStyles as any, }}
                  isDisabled={loading}
                  placeholder={intl.formatMessage({id: 'tasks.selectOrder'})}
                  onChange={(option) => onOrderSelected(option?.value)}
                  options={[
                    {value: '-created_at', label: intl.formatMessage({id: 'tasks.orderByCreatedAt'})},
                    {value: 'created_at', label: intl.formatMessage({id: 'tasks.orderByCreatedAtAsc'})}
                  ]}
                  value={{value: selectedOrder, label: selectedOrder === '-created_at' ? intl.formatMessage({id: 'tasks.orderByCreatedAt'}) : intl.formatMessage({id: 'tasks.orderByCreatedAtAsc'})}}
                />
              </div>
            )
          }
          {
            onCurrentAttemptNumberSelected && (
              <div style={{width: 120}}>
                <label className='mb-1 fw-bolder'>
                  {intl.formatMessage({id: 'tasks.filterByCurrentAttemptNumber'})}
                </label>
                <input
                  type='number'
                  disabled={loading}
                  className='form-control'
                  value={selectedAttemptNumber || null}
                  placeholder={intl.formatMessage({id: 'tasks.selectCurrentAttemptNumber'})}
                  onChange={(event) => onCurrentAttemptNumberSelected(+event.target.value)}
                />
              </div>
            )
          }
        </section>
      </menu>
      {loading ? (
        <div className='d-flex h-100px mt-10 w-100 justify-content-center align-items-center'>
          <div className='d-block spinner-border mx-auto text-primary' role='status' />
        </div>
      ) : (
        <div className='table-responsive'>
          {tasks?.length === 0 && (
            <p className='mt-6'>{intl.formatMessage({id: 'tasks.noTasks'})}</p>
          )}
          {tasks?.length > 0 && (
            <table className='table table-hover table-row-dashed table-row-gray-200 align-start gs-0 gy-4'>
              <thead className='table-header sticky-top bg-white'>
                <tr className='fw-bold'>
                  <th className='text-center ps-6 pe-4'>
                    <input
                      type='checkbox'
                      className='cursor-pointer form-check w-25px h-25px'
                      disabled={bulkState === 'loading'}
                      checked={selectedTasks.length === tasks.length}
                      onChange={() => {
                        if (selectedTasks.length === tasks.length) {
                          setSelectedTasks([])
                        } else {
                          setSelectedTasks(tasks)
                        }
                      }}
                    />
                  </th>
                  <th className='text-center'>{intl.formatMessage({id: 'tasks.id'})}</th>
                  <th className='text-center'>
                    {intl.formatMessage({id: 'tasks.taskProfile.name'})}
                  </th>
                  {displayClient && (
                    <th className='text-center'>{intl.formatMessage({id: 'tasks.client'})}</th>
                  )}
                  <th className='text-center'>{intl.formatMessage({id: 'tasks.phone'})}</th>
                  {displayPet && (
                    <th className='text-center'>{intl.formatMessage({id: 'tasks.pet'})}</th>
                  )}
                  {displayOwner && (
                    <>
                      <th className='text-center'>
                        {intl.formatMessage({id: 'tasks.taskProfile.group.name'})}
                      </th>
                      <th>{intl.formatMessage({id: 'tasks.owner'})}</th>
                    </>
                  )}
                  <th className='text-center'>{intl.formatMessage({id: 'tasks.state'})}</th>
                  <th className='text-center'>
                    {intl.formatMessage({id: 'tasks.currentAttemptNumber'})}
                  </th>
                  <th className='text-center'>{intl.formatMessage({id: 'tasks.createdAt'})}</th>
                  <th className='text-center'>{intl.formatMessage({id: 'tasks.dispatchAfter'})}</th>
                  <th className='text-center'>{intl.formatMessage({id: 'tasks.finishBefore'})}</th>
                  <th className='text-center'>
                    {intl.formatMessage({id: 'tasks.lastResolutionDate'})}
                  </th>
                </tr>
              </thead>
              <tbody>
                {tasks
                  ?.sort((a, b) => (orderById ? b.id - a.id : 1))
                  ?.map((task: TaskModel) => (
                    <tr key={task.id} className={clsx(selectedTasks.includes(task) && 'bg-light')}>
                      <td className='text-center ps-6 pe-4'>
                        <input
                          type='checkbox'
                          className='cursor-pointer form-check w-25px h-25px'
                          checked={selectedTasks.includes(task)}
                          disabled={bulkState === 'loading'}
                          onChange={() => {
                            if (selectedTasks.includes(task)) {
                              setSelectedTasks(selectedTasks.filter((t) => t.id !== task.id))
                            } else {
                              setSelectedTasks([...selectedTasks, task])
                            }
                          }}
                        />
                      </td>
                      <td>
                        <Link
                          to={
                            task?.user?.id
                              ? `/user/${task?.user?.id}/task/${task?.id}`
                              : `/task/${task?.id}`
                          }
                          className='d-inline-block link'
                        >
                          {task.id}
                        </Link>
                      </td>
                      <td>{task.task_profile?.name}</td>
                      {displayClient && (
                        <td>
                          {!!task?.user?.id ? (
                            <Link
                              to={`/user/${task?.user?.id}/profile`}
                              className='d-inline-block link'
                            >
                              {task?.user?.email || task?.user?.name || '-'}
                            </Link>
                          ) : (
                            task?.email || '-'
                          )}
                        </td>
                      )}
                      <td>
                        {task?.call_request?.phone ||
                          task?.user?.standard_phone ||
                          task?.phone ||
                          '-'}
                      </td>
                      {displayPet && (
                        <td>
                          {task?.user?.id && task?.pet?.id ? (
                            <Link
                              to={`/user/${task?.user?.id}/pet/${task?.pet?.id}`}
                              className='d-inline-block link'
                            >
                              {task?.pet?.name || '-'}
                            </Link>
                          ) : (
                            '-'
                          )}
                        </td>
                      )}
                      {displayOwner && (
                        <>
                          <td className='text-center'>
                            <span
                              className={clsx(
                                'badge fw-bolder',
                                task.task_profile?.group.name.includes('Subscription') &&
                                  'badge-light-info',
                                task.task_profile?.group.name.includes('Acquisition') &&
                                  'badge-light-success',
                                task.task_profile?.group.name.includes('Pet') && 'badge-light-dark'
                              )}
                            >
                              {task.task_profile?.group?.name?.replace('ATC - ', '')}
                            </span>
                          </td>
                          <td>{task.owner?.name}</td>
                        </>
                      )}
                      <td className='text-center'>
                        <span
                          className={clsx(
                            'badge fw-bolder',
                            solvableStates.includes(task.state)
                              ? 'badge-primary'
                              : 'badge-secondary'
                          )}
                        >
                          {task.state}
                        </span>
                      </td>
                      <td className='text-center'>
                        {task.current_attempt_number + 1}/
                        {task.current_task_profile_settings?.total_attempts || 1}
                      </td>
                      <td className='text-center'>
                        {new Date(Date.parse(task.created_at)).toLocaleString()}
                      </td>
                      <td className='text-center'>
                        {new Date(Date.parse(task.dispatch_after)).toLocaleString()}
                      </td>
                      <td className='text-center'>
                        {new Date(Date.parse(task.finish_before)).toLocaleString()}
                      </td>
                      <td className='text-center'>
                        {task?.resolution_changed_at
                          ? new Date(task?.resolution_changed_at)?.toLocaleString()
                          : '-'}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      )}
      <BulkResolutionModal
        visible={bulkResolutionModalVisible}
        tasks={selectedTasks}
        onResolve={onBulkResolution}
        onHide={() => setBulkResolutionModalVisible(false)}
      />
      <BulkCommentModal
        visible={bulkCommentModalVisible}
        tasks={selectedTasks}
        onSubmit={onBulkComment}
        onHide={() => setBulkCommentModalVisible(false)}
      />
      {selectedTasks?.length > 0 && (
        <TaskResolutionModal
          show={bulkReassignModalVisible}
          task={selectedTasks[0]}
          resolutions={selectedTasks[0].resolutions} // TODO: get all resolutions
          onResolve={onBulkReassign}
          resolutionCode={'reassign-owner'}
          onHide={() => setBulkReassignModalVisible(false)}
          staffUsers={staffUsers}
          expertStaffUsers={staffUsers}
          groups={groups}
        />
      )}
    </>
  )
}

export default TaskTable
