import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export interface RegisterWhatsappTemplateRequest {
    externalTemplateId: number;
    botId: number;
    name: string;
    minHoursToResend: number;
    archiveChat: boolean;
}

export async function registerWhatsappTemplate({
    externalTemplateId,
    botId,
    name,
    minHoursToResend,
    archiveChat,
}) {
    const params = {
        external_template_id: externalTemplateId,
        bot_id: botId,
        name,
        min_hours_to_resend: minHoursToResend,
        archive_chat: archiveChat,
    };
    const endpoint = `${API_URL}/api/crm/whatsapp-templates/`;
    
    return axios.post(endpoint, params);
}


export interface SendMassiveWhatsappRequest {
    file: File;
    code: string;
}

export async function sendMassiveWhatsapp({
    file,
    code,
}: SendMassiveWhatsappRequest) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('code', code);
    const endpoint = `${API_URL}/api/crm/whatsapp-templates/massive/`;
    
    return axios.post(endpoint, formData);
}

export async function listTemplates() {
    const endpoint = `${API_URL}/api/crm/whatsapp-templates/`;

    return axios.get(endpoint);
}