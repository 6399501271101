/* eslint-disable jsx-a11y/anchor-is-valid */
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import TemplateRegistrationForm from '../../modules/whatsapp-templates/components/TemplateRegistrationForm'
import MassiveWhatsappsForm from '../../modules/whatsapp-templates/components/MassiveWhatsappsForm'

export function WhatsappTemplatesPage() {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'menu.whatsappTemplates'})}
      </PageTitle>
      <div className='row'>
        <div className='col-6'>
         <TemplateRegistrationForm />
        </div>
        <div className='col-6'>
         <MassiveWhatsappsForm />
        </div>
      </div>
    </>
  )
}