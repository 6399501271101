import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import moment from 'moment'

import {getAtcDashboard} from '../services/DashboardCRUD'
import DateRangeSelector from '../../../components/DateRangeSelector'
import LineChart from './LineChart'
import countryNames from '../../../constants/countryNames'

export default function AtcDashboard() {
  const intl = useIntl()
  const initialSince = moment().subtract(31, 'days').toDate()
  const initialUntil = moment().subtract(1, 'days').toDate()
  const [recoveryData, setRecoveryData] = useState(null)
  const [retentionData, setRetentionData] = useState(null)
  const [subscriptionData, setSubscriptionData] = useState(null)
  const [trialData, setTrialData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [selectedStore, setSelectedStores] = useState('ES')

  function onAcceptDateRange(since, until) {
    fetchData(since, until)
  }

  function getRelevantData(data) {
    const dataFromSelectedStore = data?.filter((item) => item.store_country === selectedStore)
    const aggregatedData = []

    dataFromSelectedStore?.forEach((item, index) => {
      const existingItem = aggregatedData.find(
        (aggregatedItem) => aggregatedItem.name === item.name && aggregatedItem.date === item.date
      )

      if (existingItem) {
        existingItem.value += item.value
      } else {
        aggregatedData.push(item)
      }
    })

    return aggregatedData
  }

  async function fetchData(since, until) {
    let stringSince = `${since?.getFullYear()}-${(since?.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${since?.getDate().toString().padStart(2, '0')}`
    let stringUntil = `${until?.getFullYear()}-${(until?.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${until?.getDate().toString().padStart(2, '0')}`

    setLoading(true)
    try {
      const {data} = await getAtcDashboard({
        since: stringSince,
        until: stringUntil,
      })
      setRecoveryData(data['rescate'])
      setRetentionData(data['retention'])
      setSubscriptionData(data['subscription'])
      setTrialData(data['trials'])
    } catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(initialSince, initialUntil)
  }, [])

  function getStores() {
    return Object.keys(countryNames).map((country) => ({
      country,
      name: countryNames[country],
    }))
  }

  return (
    <div className='card pt-0'>
      <h1 className='mb-8'>
        {intl.formatMessage({id: 'atcDashboard.charts'})}
      </h1>
      <div className='mb-10 d-flex align-items-start gap-8'>
        <DateRangeSelector
          initialSince={initialSince}
          initialUntil={initialUntil}
          onSelected={onAcceptDateRange}
          disableFutureDates={true}
        />
      </div>

      <ul className='nav nav-tabs mb-10'>
        {getStores().map((store) => (
          <li className='nav-item' key={store.country}>
            <a
              className={`nav-link ${selectedStore === store.country ? 'active' : ''}`}
              aria-current='page'
              href='#'
              onClick={() => setSelectedStores(store.country)}
            >
              {store.name}
            </a>
          </li>
        ))}
      </ul>
      <LineChart
        title={intl.formatMessage({id: 'atcDashboard.trials'})}
        data={getRelevantData(trialData)}
        loading={loading}
        serieField='name'
      />
      <LineChart
        title={intl.formatMessage({id: 'atcDashboard.retentionOkKo'})}
        data={getRelevantData(retentionData).filter((item) => ['OK', 'KO'].includes(item.name))}
        loading={loading}
        serieField='name'
      />
      <LineChart
        title={intl.formatMessage({id: 'atcDashboard.retentionKo'})}
        data={getRelevantData(retentionData).filter(
          (item) => item.name && !['OK', 'KO'].includes(item.name)
        )}
        loading={loading}
        serieField='name'
      />
      <LineChart
        title={intl.formatMessage({id: 'atcDashboard.subscription'})}
        data={getRelevantData(subscriptionData)}
        loading={loading}
        serieField='name'
      />
      <LineChart
        title={intl.formatMessage({id: 'atcDashboard.rescate'})}
        data={getRelevantData(recoveryData)}
        loading={loading}
        serieField='name'
      />
    </div>
  )
}
