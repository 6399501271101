import {Modal} from 'react-bootstrap'
import {OrderModel} from '../models/OrderModel'
import {useIntl} from 'react-intl'
import {
  DistributorModel,
  ShippingAddressModel,
  BillingAddressModel,
  listDistributorBillingAddresses,
  listDistributorShippingAddresses,
} from '../../distributors'
import {useEffect, useState} from 'react'
import clsx from 'clsx'
import Select from 'react-select'

interface PropsType {
  order: OrderModel | undefined
  distributors: Array<DistributorModel>
  visible: boolean
  onCancel: () => void
  onSaveOrder: (order: OrderModel) => void
}

export default function ModalOrderForm({
  order,
  distributors,
  visible,
  onCancel,
  onSaveOrder,
}: PropsType) {
  const intl = useIntl()
  const [orderState, setOrderState] = useState<OrderModel | undefined>(order)
  const [billingAddresses, setBillingAddresses] = useState<Array<BillingAddressModel>>([])
  const [shippingAddresses, setShippingAddresses] = useState<Array<ShippingAddressModel>>([])

  const fetchAddresses = async (distributorId) => {
    if (distributorId > -1) {
      const {data: newBillingAddresses} = await listDistributorBillingAddresses(distributorId)
      const {data: newShippingAddresses} = await listDistributorShippingAddresses(distributorId)
      setBillingAddresses(newBillingAddresses)
      setShippingAddresses(newShippingAddresses)
    } else {
      setBillingAddresses([])
      setShippingAddresses([])
    }
  }

  useEffect(() => {
    setOrderState(order)
  }, [order])

  useEffect(() => {
    if (orderState?.distributor) {
      setBillingAddresses([])
      setShippingAddresses([])
      fetchAddresses(orderState.distributor)
    }
  }, [orderState?.distributor])

  useEffect(() => {
    if (billingAddresses.length > 0 && !orderState?.billing_address) {
      setOrderState({
        ...orderState,
        billing_address: billingAddresses[0].id,
      })
    }
  }, [billingAddresses])

  useEffect(() => {
    if (shippingAddresses.length > 0 && !orderState?.shipping_address) {
      setOrderState({
        ...orderState,
        shipping_address: shippingAddresses[0].id,
      })
    }
  }, [shippingAddresses])

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    onSaveOrder(orderState)
  }

  function isValid() {
    if (!orderState?.distributor || orderState?.distributor < 0) {
      return false;
    }
    if (!orderState?.billing_address || orderState?.billing_address < 0) {
      return false;
    }
    if (!orderState?.shipping_address || orderState?.shipping_address < 0) {
      return false;
    }
    if (!orderState?.promise_date) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (orderState?.discount_mode === 'absolute') {
      setOrderState({
        ...orderState,
        discount_on: 'order',
      } as OrderModel)
    }
  }, [orderState?.discount_mode])

  return (
    <Modal show={visible} onClose={onCancel}>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            {orderState?.id
              ? intl.formatMessage({id: 'b2bOrders.editOrder'}, {code: orderState?.code})
              : intl.formatMessage({id: 'b2bOrders.addOrder'})}
          </h5>
          <button type='button' className='btn-close' onClick={onCancel}></button>
        </div>
        <div className='modal-body'>
          <div className='row'>
            <form onSubmit={onSubmit}>
              <div className='col-12'>
                <div className='form-group mb-4'>
                  <label className='fw-bolder mb-1' htmlFor='distributor'>
                    {intl.formatMessage({id: 'b2bOrders.distributor'})}
                  </label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select" 
                    placeholder={intl.formatMessage({ id: 'b2bOrders.selectDistributor' })}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: '42px',
                      } as any),
                    }}
                    options={[
                      {
                        value: null,
                        label: intl.formatMessage({ id: 'b2bOrders.anyDistributor' }),
                      },
                      ...distributors.map((distributor) => ({
                        value: distributor.id,
                        label: distributor.name + (distributor.is_non_payer ? ` (${intl.formatMessage({id: 'distributors.nonPayer'})})` : ''),
                        disabled: true,
                      } as any)),
                    ]}
                    value={orderState?.distributor ?
                      {
                        value: distributors.find((d) => d.id == orderState?.distributor)?.id,
                        label: distributors.find((d) => d.id == orderState?.distributor)?.name + (distributors.find((d) => d.id == orderState?.distributor)?.is_non_payer ? ` (${intl.formatMessage({id: 'distributors.nonPayer'})})` : ''),
                      } : null}
                    onChange={(option: any) => {
                      const distributor = distributors.find((d) => d.id == option.value)

                      setOrderState({
                        ...orderState,
                        distributor: distributor?.id || null,
                      } as OrderModel)
                    }}
                    isOptionDisabled={(option) => {
                      const distributor = distributors.find((d) => d.id == option.value)
                      return distributor?.is_non_payer
                    }}
                  />
                </div>
                <div className='form-group mb-4'>
                  <label className='fw-bolder mb-1' htmlFor='billing_address'>
                    {intl.formatMessage({id: 'b2bOrders.billingAddress'})}
                  </label>
                  <select
                    disabled={!billingAddresses.length}
                    id='billing_address'
                    className={clsx({
                      'form-control form-select': true,
                      'is-invalid': !orderState?.billing_address,
                    })}
                    value={orderState?.billing_address}
                    onChange={(e) => {
                      setOrderState({
                        ...orderState,
                        billing_address: parseInt(e.target.value, 10),
                      } as OrderModel)
                    }}
                  >
                    <option value=''>
                      {intl.formatMessage({id: 'b2bOrders.selectBillingAddress'})}
                    </option>
                    {billingAddresses.map((billingAddress) => (
                      <option key={billingAddress.id} value={billingAddress.id}>
                        {billingAddress.name}, {billingAddress.street}, {billingAddress.postcode}{' '}
                        {billingAddress.city} {billingAddress.street}({billingAddress.vat_number})
                      </option>
                    ))}
                  </select>
                </div>
                <div className='form-group mb-4'>
                  <label className='fw-bolder mb-1' htmlFor='shipping_address'>
                    {intl.formatMessage({id: 'b2bOrders.shippingAddress'})}
                  </label>
                  <select
                    disabled={!shippingAddresses.length}
                    id='shipping_address'
                    className={clsx({
                      'form-control form-select': true,
                      'is-invalid': !orderState?.shipping_address,
                    })}
                    value={orderState?.shipping_address}
                    onChange={(e) => {
                      setOrderState({
                        ...orderState,
                        shipping_address: parseInt(e.target.value, 10),
                      } as OrderModel)
                    }}
                  >
                    <option value=''>
                      {intl.formatMessage({id: 'b2bOrders.selectShippingAddress'})}
                    </option>
                    {shippingAddresses.map((shippingAddress) => (
                      <option key={shippingAddress.id} value={shippingAddress.id}>
                        {shippingAddress.name}, {shippingAddress.street}, {shippingAddress.postcode}{' '}
                        {shippingAddress.city}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='form-group mb-4'>
                  <label className='fw-bolder mb-1' htmlFor='promise_date'>
                    {intl.formatMessage({id: 'b2bOrders.promiseDate'})}
                  </label>
                  <input
                    id='promise_date'
                    type='date'
                    className='form-control'
                    value={orderState?.promise_date}
                    onChange={(e) => {
                      setOrderState({
                        ...orderState,
                        promise_date: e.target.value,
                      } as OrderModel)
                    }}
                  />
                </div>
                <div className='form-group mb-4'>
                  <label className='fw-bolder mb-1' htmlFor='shipping_fee'>
                    {intl.formatMessage({id: 'b2bOrders.shippingFee'})}
                  </label>
                  <div className='input-group'>
                    <input
                      id='shipping_fee'
                      pattern='^\d+(?:\.\d{1,2})?$'
                      type='number'
                      min='0.00'
                      max='1000000.00'
                      step='0.01'
                      className='form-control'
                      value={orderState?.shipping_fee}
                      onChange={(e) => {
                        setOrderState({
                          ...orderState,
                          shipping_fee: e.target.valueAsNumber,
                        } as OrderModel)
                      }}
                    />
                    <div className='input-group-append'>
                      <span className='input-group-text'>€</span>
                    </div>
                  </div>
                </div>
                <div className='form-group mb-4'>
                  <label className='fw-bolder mb-1' htmlFor='discount_mode'>
                    {intl.formatMessage({id: 'b2bOrders.discountMode'})}
                  </label>
                  <div className='input-group'>
                    <select
                      id='discount_mode'
                      className='form-control form-select'
                      value={orderState?.discount_mode}
                      onChange={(e) => {
                        setOrderState({
                          ...orderState,
                          discount_mode: e.target.value as 'absolute' | 'relative',
                        } as OrderModel)
                      }}
                    >
                      <option value='absolute'>
                        {intl.formatMessage({id: 'b2bOrders.absolute'})}
                      </option>
                      <option value='relative'>
                        {intl.formatMessage({id: 'b2bOrders.relative'})}
                      </option>
                    </select>
                  </div>
                </div>
                <div className='form-group mb-4'>
                  <label className='fw-bolder mb-1' htmlFor='discount_on'>
                    {intl.formatMessage({id: 'b2bOrders.discountOn'})}
                  </label>
                  <div className='input-group'>
                    <select
                      id='discount_on'
                      className='form-control form-select'
                      value={orderState?.discount_on}
                      onChange={(e) => {
                        setOrderState({
                          ...orderState,
                          discount_on: e.target.value as 'order' | 'order_line',
                        } as OrderModel)
                      }}
                    >
                      <option value='order'>
                        {intl.formatMessage({id: 'b2bOrders.wholeOrder'})}
                      </option>
                      <option value='order_line' disabled={orderState?.discount_mode === 'absolute'}>
                        {intl.formatMessage({id: 'b2bOrders.orderLine'})}
                      </option>
                    </select>
                  </div>
                </div>
                <div className='form-group mb-4'>
                  <label className='fw-bolder mb-1' htmlFor='total_discounts'>
                    {intl.formatMessage({id: 'b2bOrders.totalDiscounts'})}
                  </label>
                  <div className='input-group'>
                    <input
                      id='total_discounts'
                      pattern='^\d+(?:\.\d{1,2})?$'
                      type='number'
                      min='0.00'
                      max='1000000.00'
                      step='0.01'
                      className='form-control'
                      value={orderState?.total_discounts}
                      onChange={(e) => {
                        setOrderState({
                          ...orderState,
                          total_discounts: e.target.valueAsNumber,
                        } as OrderModel)
                      }}
                    />
                    <div className='input-group-append'>
                      <span className='input-group-text'>
                        {
                          orderState?.discount_mode === 'absolute'
                            ? '€'
                            : '%'
                        }
                      </span>
                    </div>
                  </div>
                </div>
                <div className='form-group mb-4'>
                  <label className='fw-bolder mb-1' htmlFor='comments'>
                    {intl.formatMessage({id: 'b2bOrders.externalReference'})}
                  </label>
                  <input
                    id='external_reference'
                    type='text'
                    className='form-control'
                    value={orderState?.external_reference}
                    onChange={(e) => {
                      setOrderState({
                        ...orderState,
                        external_reference: e.target.value,
                      } as OrderModel)
                    }}
                  />
                </div>
              </div>
              <button
                disabled={!isValid()}
                type='submit'
                className='btn btn-primary ms-auto d-block'
              >
                {orderState?.id
                  ? intl.formatMessage({id: 'b2bOrders.saveChanges'})
                  : intl.formatMessage({id: 'b2bOrders.createOrder'})}
              </button>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  )
}
