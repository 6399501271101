import {Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from './FallbackView'
import {HomePage} from '../pages/atc/HomePage'
import {UserProfilePage} from '../pages/atc/UserProfilePage'
import {PetsPage} from '../pages/atc/PetsPage'
import {PetDetailPage} from '../pages/atc/PetDetailPage'
import {WalletPage} from '../pages/atc/WalletPage'
import {PlansPage} from '../pages/atc/PlansPage'
import {OrdersPage} from '../pages/atc/OrdersPage'
import {TasksPage} from '../pages/atc/TasksPage'
import {PlanDetailPage} from '../pages/atc/PlanDetailPage'
import {OrderDetailPage} from '../pages/atc/OrderDetailPage'
import {TaskDetailPage} from '../pages/atc/TaskDetailPage'
import {NotReachablePage} from '../pages/atc/NotReachablePage'
import {AgendaPage} from '../pages/atc/AgendaPage'
import {AlarmsPage} from '../pages/atc/AlarmsPage'
import {PossiblePage} from '../pages/atc/PossiblePage'
import {PendingCustomerPage} from '../pages/atc/PendingCustomerPage'
import {AgentTasksPage} from '../pages/atc/AgentTasksPage'
import {TasksPerAgentPage} from '../pages/atc/TasksPerAgentPage'
import {RecoveryTasksPage} from '../pages/atc/RecoveryTasksPage'
import {UnknownTasksPage} from '../pages/atc/UnknownTasksPage'
import {NewLeadsPage} from '../pages/atc/NewLeadsPage'
import {NoRecipesPage} from '../pages/atc/NoRecipesPage'
import {LeadCallRequestsPage} from '../pages/atc/LeadCallRequestsPage'
import {NoAnswerPage} from '../pages/atc/NoAnswerPage'
import {BusinessDashboardPage} from '../pages/business/BusinessDashboardPage'
import {AtcDashboardPage} from '../pages/atc/AtcDashboardPage'
import {CommentsPage} from '../pages/atc/CommentsPage'
import {AgentPerformancePage} from '../pages/atc/AgentPerformancePage'
import {shallowEqual, useSelector} from 'react-redux'
import {authUserSelector} from '../modules/auth'
import {authorizedBusinessDashboardUsers, authorizedAtcDashboardUsers} from '../modules/dashboard'
import {LogsPage} from '../pages/atc/LogsPage'
import {ShopsPage} from '../pages/b2b/ShopsPage'
import {DistributorsPage} from '../pages/b2b/DistributorsPage'
import {InvoicesPage} from '../pages/b2b/InvoicesPage'
import {DeliveryNotesPage} from '../pages/b2b/DeliveryNotesPage'
import superUsers from '../modules/tasks/constants/superUsers'
import {DistributorDetailPage} from '../pages/b2b/DistributorDetailPage'
import {B2BOrdersPage} from '../pages/b2b/B2BOrdersPage'
import {B2BLeadOrdersPage} from '../pages/b2b/B2BLeadOrdersPage'
import {OperationOrdersPage} from '../pages/operations/OperationOrdersPage'
import DeliveryChangesPage from '../pages/operations/DeliveryChangesPage'
import {MealPricingPage} from '../pages/business/MealPricingPage'
import {LeadDistributorsPage} from '../pages/b2b/LeadDistributorsPage'
import {LeadDistributorDetailPage} from '../pages/b2b/LeadDistributorDetailPage'
import { LeadDistributorReportPage } from '../pages/b2b/LeadDistributorReportPage'
import { ShopifyOrdersPage } from '../pages/b2b/ShopifyOrdersPage'
import { SearchShopsPage} from '../pages/b2b/SearchShopsPage'
import {WhatsappLogsPage} from '../pages/atc/WhatsappLogsPage'
import {ShopifyImportPage} from '../pages/business/ShopifyImportPage'
import { UserInvoicesPage } from '../pages/atc/UserInvoicesPage'
import {InfluencerStatsPage} from '../pages/atc/InfluencerStatsPage'
import { PlanCalculatorPage } from '../pages/atc/PlanCalculatorPage'
import { OrderTrackingHistoryPage } from '../pages/atc/OrderTrackingHistoryPage'
import { LoyaltyRewardsPage } from '../pages/business/LoyaltyRewardsPage'
import { CallLogsPage } from '../pages/atc/CallLogsPage'
import { SegmentedReportPage } from '../pages/business/SegmentedReportPage'
import { WhatsappTemplatesPage } from '../pages/atc/WhatsappTemplatesPage'

export function PrivateRoutes() {
  const authUser = useSelector(authUserSelector, shallowEqual)
  const isB2BUser = !!authUser?.groups?.find((group) => group.name === 'B2B')
  const isOperationsUser = !!authUser?.groups?.find((group) => group.name === 'Operations')
  const DEFAULT_PATH = (() => {
    if (isOperationsUser) {
      return '/operation-orders'
    }
    if (isB2BUser) {
      return '/shops'
    }
    return '/dashboard'
  })()

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={HomePage} />
        <Route path='/plan-calculator' component={PlanCalculatorPage} />
        <Route path='/user/:id/logs' component={LogsPage} />
        <Route path='/user/:id/pet/:petId/logs' component={LogsPage} />
        <Route path='/user/:id/plan/:planId/logs' component={LogsPage} />
        <Route path='/user/:id/order/:orderId/logs' component={LogsPage} />
        <Route path='/user/:id/profile' component={UserProfilePage} />
        <Route path='/user/:id/comments' component={CommentsPage} />
        <Route path='/user/:id/wallet' component={WalletPage} />
        <Route path='/user/:id/pets/' component={PetsPage} />
        <Route path='/user/:id/plans' component={PlansPage} />
        <Route path='/user/:id/orders' component={OrdersPage} />
        <Route path='/user/:id/invoices' component={UserInvoicesPage} />
        <Route path='/user/:id/pet/:petId' component={PetDetailPage} />
        <Route path='/user/:id/plan/:planId' component={PlanDetailPage} />
        <Route path='/user/:id/order/:orderId' component={OrderDetailPage} />
        <Route path='/user/:id/tasks' component={TasksPage} />
        <Route path='/user/:id/task/:taskId' component={TaskDetailPage} />
        <Route path='/user/:id/whatsapp-logs' component={WhatsappLogsPage} />
        <Route path='/user/:id/call-logs' component={CallLogsPage} />
        <Route path='/task/:taskId' component={TaskDetailPage} />
        <Route path='/not-reachable' component={NotReachablePage} />
        <Route path='/no-answer' component={NoAnswerPage} />
        <Route path='/possible' component={PossiblePage} />
        <Route path='/pending-customer' component={PendingCustomerPage} />
        <Route path='/recovery' component={RecoveryTasksPage} />
        <Route path='/unknown' component={UnknownTasksPage} />
        <Route path='/new-leads' component={NewLeadsPage} />
        <Route path='/no-recipes' component={NoRecipesPage} />
        <Route path='/lead-call-requests' component={LeadCallRequestsPage} />
        <Route path='/agenda' component={AgendaPage} />
        <Route path='/alarms' component={AlarmsPage} />
        <Route path='/tasks-per-agent' component={TasksPerAgentPage} />
        {superUsers.includes(authUser?.email) && (
          <Route path='/agent-performance' component={AgentPerformancePage} />
        )}
        <Route path='/agent-tasks/:id' component={AgentTasksPage} />
        <Route path='/unassigned-tasks' component={AgentTasksPage} />
        {authorizedBusinessDashboardUsers.includes(authUser?.email) && (
          <Route path='/business-dashboard' component={BusinessDashboardPage} />
        )}
        {authorizedBusinessDashboardUsers.includes(authUser?.email) && (
          <Route path='/segmented-report' component={SegmentedReportPage} />
        )}
        {authorizedAtcDashboardUsers.includes(authUser?.email) && (
          <Route path='/atc-dashboard' component={AtcDashboardPage} />
        )}
        <Route path='/influencer-stats' component={InfluencerStatsPage} />
        <Route path='/shops' component={ShopsPage} />
        <Route path='/lead-distributors' component={LeadDistributorsPage} />
        <Route path='/lead-distributor/:leadDistributorId' component={LeadDistributorDetailPage} />
        <Route path='/b2b-lead-orders' component={B2BLeadOrdersPage} />
        <Route path='/distributors' component={DistributorsPage} />
        <Route path='/distributor/:distributorId' component={DistributorDetailPage} />
        <Route path='/b2b-orders' component={B2BOrdersPage} />
        <Route path='/invoices' component={InvoicesPage} />
        <Route path='/delivery-notes' component={DeliveryNotesPage} />
        <Route path='/operation-orders' component={OperationOrdersPage} />
        <Route path='/shopify-orders' component={ShopifyOrdersPage} />
        <Route path='/shopify-import' component={ShopifyImportPage} />
        <Route path='/delivery-changes' component={DeliveryChangesPage} />
        <Route path='/lead-distributor-report' component={LeadDistributorReportPage} />
        <Route path='/meal-pricing' component={MealPricingPage} />
        <Route path='/search-shops' component={SearchShopsPage} />
        <Route path='/order-tracking-history' component={OrderTrackingHistoryPage} />
        <Route path='/loyalty-rewards' component={LoyaltyRewardsPage} />
        <Route path='/whatsapp-templates' component={WhatsappTemplatesPage} />
        <Redirect from='/auth' to={DEFAULT_PATH} />
        <Redirect exact from='/' to={DEFAULT_PATH} />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
